import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
// import VideoPlaceholder from './../../assets/images/video-placeholder.jpg';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Create audiovisual art with <span className="text-color-primary">ShaderBoi</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                ShaderBoi is a powerful tool for artistic shader development, producing immersive (music) videos, live streaming, VJing and art installations.
                <br/>
                ShaderBoi allows you to develop your <a href="https://www.shadertoy.com" target="_blank" rel="noreferrer">shadertoy</a> shaders locally on your computer using your favorite editor (with auto-reloading). You can render your shader live (e.g. during development or VJing) or as high-quality video!
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://bit.ly/ShaderBoi-releases" target="_blank" rel="noreferrer">
                    Download (for Windows/Linux/Mac)
                  </Button>
                  <Button tag="a" color="dark" wideMobile href="https://discord.com/invite/Ef4kGQxYeK" target="_blank" rel="noreferrer">
                    Join our Discord
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <div className="overlay-parent">
                {/*<div className="box">*/}
                  <Image
                    className="has-shadow"
                    src="https://i.ytimg.com/vi/ugZGjtAsWYE/maxresdefault.jpg"
                    alt="Click to play video"
                    title="Click to play video"
                    width={896}
                    height={504}
                  />
                {/*</div>*/}
                {/*TODO: Overlay that makes video placeholder image darker and shows a play button*/}
                <div className="box overlay">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg">
                    <title>Click to play video</title>
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
                  </svg>
                </div>
              </div>
            </a>
          </div>
          <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://www.youtube.com/embed/ugZGjtAsWYE?rel=0&autoplay=1&vq=hd1080"
            videoTag="iframe"
          />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
