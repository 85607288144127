import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import FeatureTileIcon1 from '../../assets/images/feature-tile-icon-01.svg';
import FeatureTileIcon2 from '../../assets/images/feature-tile-icon-02.svg';
import FeatureTileIcon3 from '../../assets/images/feature-tile-icon-03.svg';
import FeatureTileIcon4 from '../../assets/images/feature-tile-icon-04.svg';
import FeatureTileIcon5 from '../../assets/images/feature-tile-icon-05.svg';
import FeatureTileIcon6 from '../../assets/images/feature-tile-icon-06.svg';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: "Unleash your creativity with the power of ShaderBoi",
    paragraph: "ShaderBoi has powerful features, such as:"
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon1}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Shader live coding outside the browser
                  </h4>
                  <p className="m-0 text-sm">
                    {/*Have you ever wanted to render your shadertoy shaders outside of the browser?*/}
                    ShaderBoi renders your shaders natively on your computer using OpenGL.
                    You can use your favorite editor to edit your shaders, ShaderBoi will auto-reload them.
                    {/*e.g. for shader live coding, VJing or rendering high-quality videos or animated GIFs from your shaders?*/}
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon2}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Render videos from your shaders
                  </h4>
                  <p className="m-0 text-sm">
                    Render in high-quality, e.g. 1080p, 4K or 8K resolution, 60 FPS, synced to your audio/midi, ready for upload to Youtube/Instagram/Twitter/Facebook.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon3}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    VJing with shaders
                  </h4>
                  <p className="m-0 text-sm">
                    Perform live with ShaderBoi, either in fullscreen mode with a projector, or live stream with OBS. Send midi live from your DAW or DJing app into ShaderBoi to create immersive synesthetic experiences.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon4}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Powerful shader inputs
                  </h4>
                  <p className="m-0 text-sm">
                    ShaderBoi supports many different shader inputs, e.g. webcams, midi, audio, videos, images, GIFs, cubemaps / 360° panoramas, textures (1D/2D/3D), …
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon5}
                      alt="Features tile icon 05"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Modular development and reusability
                  </h4>
                  <p className="m-0 text-sm">
                    Maximize reusability by splitting your code into multiple shaders and layering them in a chain.
                    A project can contains any number of shader chains, and you can specify the routing between the chains.
                    ShaderBoi supports modular shader development and importing shaders directly from shadertoy.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon6}
                      alt="Features tile icon 06"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Render 360°/VR videos with spatial (ambisonic) audio
                  </h4>
                  <p className="m-0 text-sm">
                    ShaderBoi allows you to render 360°/VR videos (from VR shaders), even with spatial audio, and injects the right metadata, the resulting file is ready for upload to platforms that support it Youtube/FB etc.
                    You can also VJ in 360° on a Youtube stream or in the metaverse.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
