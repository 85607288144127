import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
/*import Image from '../elements/Image';
import FeaturesSplitImage1 from './../../assets/images/features-split-image-01.png';
import FeaturesSplitImage2 from './../../assets/images/features-split-image-02.png';
import FeaturesSplitImage3 from './../../assets/images/features-split-image-03.png';*/

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Video Gallery',
    paragraph: 'Here you can see some videos that were created with ShaderBoi'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Created by SunnyBadgr based on shader by Inigo Quilez, with music by KAS:ST
                </div>
                <h3 className="mt-0 mb-12">
                  Road To Nowhere [360° 8K]
                  </h3>
                <p className="m-0">
                  — This is an example 360° video rendered in 8K resolution with ShaderBoi. The shader was adapted from Inigo Quilez's <a href="https://shadertoy.com/view/llj3Rz" target="_blank" rel="noreferrer">Space Curvature shader</a>, turned into a VR shader and layers were added to make it react to the music. (Best viewed on Youtube in 8K resolution.)
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/*<Image
                  src={FeaturesSplitImage3}
                  alt="Features split 03"
                  width={528}
                  height={396} />*/}
                <iframe width="528" height="396" src="https://www.youtube.com/embed/W_jr8cCD2sU?rel=0" title="KAS:ST - Road To Nowhere [360° 8K Visual]" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Created by Michele Zaccagnini
                </div>
                <h3 className="mt-0 mb-12">
                  Hyperborean Sun
                </h3>
                <p className="m-0">
                  — Hyperborean Sun was initially submitted at the Revision Demoparty 2022. This is a revised and improved version. The shader was made in collaboration with NR4. Entirely programmed in GLSL Shadertoy using midi inputs and rendered offline via ShaderBoi.
                  Music is composed in MaxMSP, sounds are produced in VCV Rack.
                  Mixed and mastered by Andrea Lepori.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/*<Image
                  src={FeaturesSplitImage1}
                  alt="Features split 01"
                  width={528}
                  height={396} />*/}
                <iframe width="528" height="396" src="https://www.youtube.com/embed/-6gBBSq1Zq0?rel=0" title="Hyperborean Sun" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Created by Michele Zaccagnini
                </div>
                <h3 className="mt-0 mb-12">
                  Pluto is Made of 4
                </h3>
                <p className="m-0">
                  — First place at Demosplash 2021 hosted by Carnegie Mellon University.
                  Music and visuals by Michele Zaccagnini. Mixing and mastering by Damian Schmidtke.
                  Music composed in Max8 and Ableton, visuals coded in Shadertoy and rendered via ShaderBoi.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/*<Image
                  src={FeaturesSplitImage2}
                  alt="Features split 02"
                  width={528}
                  height={396} />*/}
                <iframe width="528" height="396" src="https://www.youtube.com/embed/z19Irh2WeOY?rel=0" title="Pluto is Made of 4 4k" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Created by Michele Zaccagnini
                </div>
                <h3 className="mt-0 mb-12">
                  Oil Spill
                </h3>
                <p className="m-0">
                  — Oil Spill is an audiovisual piece composed using different techniques and tools. The music is composed using a rhythmic generative technique in MaxMSP. The sounds are made with modular synthesizer VCV Rack. Visuals are prototyped in Jitter, MaxMSP and developed with ShaderBoi, which allowed me to use midi files to generate the visuals. All animation and camera motion is coded in the shader. Water effect, tentacle objects and lighting schemes are adapted from shaders by Inigo Quilez and Fizzr.
                    Thanks to SunnyBadgr for his advice, help and for developing such a great tool for audiovisual composers!
                    Thanks to Travis Thatcher for help with mixing!
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <iframe width="528" height="396" src="https://www.youtube.com/embed/EVNZsfrSYGQ?rel=0" title="Oil Spill" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Created by Michele Zaccagnini
                </div>
                <h3 className="mt-0 mb-12">
                  Submerged World (REPRISE)
                </h3>
                <p className="m-0">
                  — Submerged World is a 360° video with ambisonic sound (360). The sound responds to user interaction either by moving the mobile device or by dragging the video around on a computer. Each octopus is a "voice" in the musical texture, each jellyfish produces a sound each time it propels itself forward, it also radiates color through its tentacles.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <iframe width="528" height="396" src="https://www.youtube.com/embed/LvUt317FEJA?rel=0" title="Submerged World (REPRISE)" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
